import { Link } from "react-router-dom";
// import OurWorkBgImg from "../../../assets/5.webp";
import OurWorkCard from "../reusable-components/OurWorkCard";
import { ourWorkData } from "../../../common/data";

function MpOurWork() {
  return (
    <section id="our-work"> 
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            {/* <h2 className="text-light text-center">
              <b>Our Work</b>
            </h2> */}
          </div>
          <div className="col-md-6 my-1"> 
            <h3 className="text-light text-center">
              <b>Website Devlopment Services</b>
            </h3>
            <p className="lh-lg text-white">
            We provide dynamic, user-friendly website development services, delivering robust digital solutions tailored to client needs and we provide End-to-End API Integration. Our expertise spans platforms like Google Cloud, AWS, cPanel, and Hostinger, ensuring scalable and reliable results for businesses of all sizes.
             </p> 
              <div className="row">
                <div className="col-md-6 my-1">
                  <div className="card card-body bg-transparent border-light h-100">               
                    <p className="lh-lg text-white">
                      <b>Informational Websites:</b>
                      <br />
                      BookMeriGadi.com <br />
                      WeBookWeb.com <br />
                      JansudharNashamuktikendra.com <br />
                      KushmandaHostel.in <br />
                      MojoClass.in <br />
                    </p> 
                  </div> 
                </div>
                <div className="col-md-6 my-1">             
                  <div className="card card-body bg-transparent border-light h-100">  
                    <p className="lh-lg text-white">
                      {/* <b>Complete Solutions with Admin & User Panels:</b><br /> */}

                      <b>BookMeriGadi.com:</b> A powerful CRM and booking system for efficient management and user engagement.<br />
                      <b>WeBookWeb.com:</b> A complete solution with admin and user panels, featuring an intuitive student and teacher panel system for paid internship and trainings. <br />
                    </p> 
                  </div>
                </div>
              </div>  
              <p className="lh-lg text-white">
              <b>Why Choose Us?</b> <br />
              We cater to a variety of requirements, from basic websites to advanced platforms with custom features. Our solutions are designed to be user-friendly, secure, and scalable.
              <br />
              Explore Our Demo Projects <br />
              Visit our demo section to experience our expertise firsthand and discover how we can help you build your dream website!
              {/* <!-- With VyomsCode, witness the fusion of artistry and technology in every project we undertake. --> */}
            </p>
            <div className="row">
              {ourWorkData.map((item) => {
                return (
                  <OurWorkCard
                    key={item.id}
                    workImg={item.workImg}
                    title={item.title}
                    goToLink={item.goToLink}
                  />
                );
              })}
            </div>
            <Link to="work">
              <button className="btn btn-warning my-3 w-25">Explore</button>
            </Link>
          </div>

          <div className="col-md-6 my-1"> 
            <h3 className="text-light  text-center">
              <b>Application Devlopment Services</b>
            </h3>
            <p className="lh-lg text-white  "> 
              We specialize in creating innovative, feature-rich applications tailored to diverse business needs. Our expertise includes seamless admin, sub-admin, and user panel integration, delivering perfect UI/UX, API support, SMS login, push notifications with custom sounds, and much more.
            </p> 
            <h4 className="text-light ">
              <b>Notable Projects</b>
            </h4>
            <p className="lh-lg text-white  ">  
             <b>BookMeriGadi App:</b> A comprehensive application designed for booking and service management. It features robust admin and user panels, intuitive UI, and advanced functionalities like SMS login and push notifications. Available on the Play Store for a hands-on experience.
            </p>
            <p className="lh-lg text-white "> 
              <b>BMGConnect App:</b> A versatile application for streamlined operations and enhanced connectivity. With admin, sub-admin, and user panel capabilities, it offers an exceptional user interface, custom notifications, and secure login options. Also available for tryouts on the Play Store.
            </p>
            <p className="lh-lg text-white "> 
              <b>Why Choose Us?</b>
            </p>
            <p className="lh-lg text-white mb-0"> 
              Multi-Platform Expertise: We develop apps for Android, iOS, and Windows with seamless functionality.
            </p>
            <p className="lh-lg text-white mb-0"> 
              Advanced Features: Includes admin panels, SMS login, API integration, and custom push notifications.
            </p>
            <p className="lh-lg text-white mb-0"> 
              Scalable and Reliable: Robust, secure, and tailored for growth.
            </p>
            <p className="lh-lg text-white mb-0"> 
              User-Centric Design: Intuitive UI/UX for an exceptional experience.
            </p> 
            <p className="lh-lg text-white "> 
              Explore these applications to experience our advanced development solutions firsthand!
            </p>  
            <Link to="work">
              <button className="btn btn-warning my-3 w-25">Explore</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MpOurWork;
