import { useRef } from "react";
import {Helmet} from "react-helmet";
import InternshipImgo from "../../assets/InternshipImgone.jpg"
import InternshipImgtw from "../../assets/InternshipImagetwo.jpg"

function PaidInternship(){
  const formRef = useRef<HTMLFormElement>(null); 
  return (
    <section
      id="contact"
      style={{ backgroundImage: "linear-gradient(black, #070F2B)" }}
    >
    <Helmet>
      <meta charSet="utf-8" />
      <title>Internships at Vyomscode | Kickstart Your Career</title>
      <meta
        name="description"
        content="Apply for paid internships at Vyomscode and gain real-world experience in web development, app development, digital marketing, and graphic design. Start your career with us!"
      />
      <meta
        name="keywords"
        content="paid internships Vyomscode, internships for web development, app development internship, digital marketing internships, graphic design internship, IT internships India, tech industry internships, learn web development, paid training Vyomscode, internships for freshers, software development internships, hands-on coding experience, digital marketing training, build your career Vyomscode, software internship programs, real-world project internships, internship opportunities Vyomscode, start your career in tech, IT paid internship program, practical experience for students"
      />
    </Helmet>

      <div className="container pt-5 text-white pb-md-5">
        <h2 className="color-blue text-center">
          <strong>Vyomscode Paid Internship Program</strong>
        </h2>
        <p className="color-blue text-center py-2 mb-3">
          <strong>Empowering Future Developers: Learn, Build, and Succeed with Vyomscode</strong>
        </p>

        <div className="row align-items-center py-5">
          <div className="col-md-6 my-1"> 
            <h4 className="color-blue">
              <strong>Kickstart Your Career in Full Web Development!</strong>
            </h4>
            <p className="color-blue py-2 mb-3 lh-lg">
              <strong>Vyomscode is excited to offer a 3-month paid internship that provides hands-on training in Full Web Development, equipping you with the skills and confidence to succeed in the tech industry. This internship is thoughtfully divided into Frontend Development and Backend Development modules, with additional professional development perks to help you shine in the job market.</strong>
            </p>
          </div>
          <div className="col-md-6 my-1 d-flex justify-content-center">
            <img
              // src="https://img.freepik.com/premium-vector/web-development-isolated-cartoon-concept-developers-customize-optimize-pages-office_9209-6631.jpg?w=740"
              src={InternshipImgo}
              alt="Vyomscode Work path"
              className="path-img w-50 rounded"
            />
          </div>  
        </div>
        <div className="row align-items-center justify-content-center py-3">
          <div className="col-md-6 my-1 d-flex justify-content-center">
            <img
              src={InternshipImgtw}
              alt="Vyomscode Work path"
              className="path-img rounded"
            />
          </div>  
          <div className="col-md-6 my-1">
          <h4 className="color-blue lh-lg">
              <strong>Why Choose Vyomscode's Internship?</strong>
            </h4>
            <p className="color-blue py-2 mb-3"> 
              <strong>Comprehensive Curriculum:</strong> Learn both frontend and backend development with real-world projects.  <br /><br />
              <strong>Hands-On Experience:</strong> Work on 15 live projects to build your practical knowledge. <br /><br />
              <strong>Professional Development:</strong> Receive guidance on social media branding, resume creation, and job applications. <br /><br />
              <strong>Mock Interviews:</strong> Practice with 2 mock interviews to prepare for real-world scenarios. <br /><br />
              <strong>Certification:</strong> Get a Vyomscode Internship Certificate upon successful completion. <br /><br />
            </p> 
          </div> 
        </div>
        <div className="row justify-content-center py-3">
          <div className="col-12">
            <h3 className="color-blue text-center">
              <strong>Internship Breakdown</strong>
            </h3>
          </div>
          <div className="col-md-4 my-1">
            <div className="card card-body h-100 bg-transparent border-light"> 
              <h4 className="text-info text-center">
                <strong>Frontend Development (6 weeks)</strong>
              </h4> 
              <p className="color-blue mb-0 lh-lg"> 
                Master the art of building stunning and responsive websites using: <br />
                <strong className="text-info">HTML:</strong> Structure the web pages effectively.  <br /> 
                <strong className="text-info">CSS:</strong> Style your web pages with creativity. <br /> 
                <strong className="text-info">JavaScript:</strong> Add dynamic and interactive features. <br /> 
                <strong className="text-info">Bootstrap:</strong> Build responsive designs quickly. <br /> 
                <strong className="text-info">Tailwind CSS:</strong> Simplify styling with utility-first CSS. <br /> 
                <strong className="text-info">Projects:</strong> Gain practical experience with 10 hands-on projects that showcase your frontend expertise. <br />
              </p> 
            </div>
          </div>
          <div className="col-md-4 my-1">
            <div className="card card-body h-100 bg-transparent border-light"> 
              <h4 className="text-info text-center">
                <strong>Backend Development (6 weeks)</strong>
              </h4> 
              <p className="color-blue mb-0 lh-lg"> 
                Delve into the core of web functionality by mastering: <br />
                <strong className="text-info">MySQL:</strong> Learn to design and manage databases.<br /> 
                <strong className="text-info">Database Design:</strong> Understand schemas and relationships.<br /> 
                <strong className="text-info">phpMyAdmin:</strong> Efficiently manage MySQL databases.<br /> 
                <strong className="text-info">PHP:</strong> Build robust and scalable backend systems.<br /> 
                <strong className="text-info">Projects:</strong> Complete 5 backend-focused projects to demonstrate your backend skills. <br /> 
              </p> 
            </div>
          </div>
        </div> 
        <div className="row align-items-center justify-content-center py-3"> 
          <div className="col-md-7 my-1 lh-lg">
            <h4 className="text-info">
              <strong>Additional Benefits</strong>
            </h4>
            <p className="color-blue py-2 mb-3 lh-lg"> 
              Vyomscode goes beyond technical training to prepare you for a successful career: <br /> 

              <strong className="text-info">Social Media Profile Creation:</strong>  Build a standout professional LinkedIn profile. <br /> 
              <strong className="text-info">Web Portfolio Development:</strong>  Showcase your work and skills with a personal portfolio website. <br /> 
              <strong className="text-info">Resume Assistance:</strong>  Craft a compelling resume to impress employers. <br /> 
              <strong className="text-info">Job Application Guidance:</strong>  Get tips and support in applying for tech roles. <br /> 
              <strong className="text-info">Mock Interviews:</strong>  Practice with 2 realistic mock interviews to boost your confidence. <br /> 
            </p> 
          </div> 
          <div className="col-md-5 my-1 lh-lg">
            <h4 className="text-info">
              <strong>Program Details</strong>
            </h4>
            <p className="color-blue py-2 mb-3 lh-lg"> 
              <strong className="text-info">Duration:</strong>  3 months (Part-time or Full-time options available)<br /> 
              <strong className="text-info">Certificate:</strong>  Vyomscode Internship Certificate upon successful completion.<br /> 
              <strong className="text-info">Who Can Apply:</strong>  Freshers, students, and anyone passionate about web development.<br /> 
              <strong className="text-info">Fee:</strong>  Paid internship with a minimal fee for lifetime value.<br /> 
            </p> 
          </div> 
        </div>


        <div className="row my-3 justify-content-center align-items-center ">
          <div className="col-md-6">
            <h4 className="color-blue">
              <strong>How to Apply?</strong>
            </h4> 
            <p className="color-blue py-2 mb-3 lh-lg"> 
              Interested in joining our internship program? Fill out the form to secure your spot and take the first step toward an exciting career in web development.

              Let Vyomscode be your gateway to a bright future in tech!
            </p> 
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <div className="card card-body border-0 bg-blue min-card">
              <form
                ref={formRef}
                id="form"
                //  onSubmit={handleSubmit}
                action="https://vyomscode.com/contactform.php"
                method="post"
                onSubmit={(e) => {
                  const textarea = document.querySelector<HTMLTextAreaElement>('textarea[name="msg"]');
                  if (textarea) {
                    const additionalText = "Paid Internship Query";
                    textarea.value += additionalText; // Appending predefined text to the user's message
                  }              
                }}
              >
                <div className="row">
                  <div className="col-12 py-2">
                    <input
                      type="name"
                      name="name"
                      className="form-control bg-blue-light"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="email"
                      name="email"
                      className="form-control bg-blue-light"
                      placeholder="Your Email Address"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="number"
                      name="number"
                      className="form-control bg-blue-light"
                      placeholder="Your Mobile Number"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <textarea
                      name="msg"
                      id=""
                      cols={35}
                      className="form-control bg-blue-light"
                      rows={5}
                      placeholder="How Can I help You"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 d-flex justify-content-cneter">
                    <button
                      id="submit"
                      type="submit"
                      className="btn btn-info w-100"
                    >
                      Submit
                    </button>
                    {/* <button
                      id="submit"
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-warning w-100"
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaidInternship
