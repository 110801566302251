import { useRef } from "react";
import {Helmet} from "react-helmet";

function ContactUs() {
  const formRef = useRef<HTMLFormElement>(null); 
  return (
    <section
      id="contact"
      style={{ backgroundImage: "linear-gradient(black, #070F2B)" }}
    >
    <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Vyomscode | Let's Bring Your Project to Life</title>
          <meta
            name="description"
            content="Contact Vyomscode to discuss your web, mobile app, or other digital project. Our team serves clients across India including Delhi, Mumbai, Varanasi, Lucknow, and more."
          />
          <meta 
          name="keywords" 
          content="contact web development company, project kaise shuru kare, website banwane ke liye contact kare, digital services inquiry, app development ke liye baat kare, contact Vyomscode, reach out for software solutions, software development kaise karwaye, website banane ke liye baat kare Vyomscode, get a quote Vyomscode, schedule a consultation, talk to experts FROM Vyomscode, discuss your project, connect with us Vyomscode, contact web development company, reach app development agency, get in touch for custom software, Delhi digital agency contact, Mumbai app dev inquiry, Varanasi web services contact, Lucknow development services" />
      </Helmet>
      <div className="container pt-5 text-white pb-md-5">
        <h2 className="color-blue text-center">
          <strong>Contact Us - We'll Reach Out to You Soon!</strong>
        </h2>
        <p className="color-blue text-center py-2 mb-3">
          <strong>Fill in the Form Below and Expect Our Prompt Response</strong>
        </p>
        <div className="row my-3 justify-content-center align-items-center ">
          <div className="col-md-6 d-flex justify-content-center">
            <div className="card card-body border-0 bg-blue min-card">
              <form
                ref={formRef}
                id="form"
                //  onSubmit={handleSubmit}
                action="https://vyomscode.com/contactform.php"
                method="post"
                onSubmit={(e) => {
                  const textarea = document.querySelector<HTMLTextAreaElement>('textarea[name="msg"]');
                  if (textarea) {
                    const additionalText = "Contact Us";
                    textarea.value += additionalText; // Appending predefined text to the user's message
                  }              
                }}
              >
                <div className="row">
                  <div className="col-12 py-2">
                    <input
                      type="name"
                      name="name"
                      className="form-control bg-blue-light"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="email"
                      name="email"
                      className="form-control bg-blue-light"
                      placeholder="Your Email Address"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="number"
                      name="number"
                      className="form-control bg-blue-light"
                      placeholder="Your Mobile Number"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <textarea
                      name="msg"
                      id=""
                      cols={35}
                      className="form-control bg-blue-light"
                      rows={5}
                      placeholder="How Can I help You"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 d-flex justify-content-cneter">
                    <button
                      id="submit"
                      type="submit"
                      className="btn btn-warning w-100"
                    >
                      Submit
                    </button>
                    {/* <button
                      id="submit"
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-warning w-100"
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
