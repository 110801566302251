import { Link } from "react-router-dom";

// web work data
import { webWorkData } from "../../../common/data";
import WebWorkCard from "../../reusables/WebWorkCard";

function WebWork() {
  return (
    <section id="web" style={{ backgroundColor: "rgb(8, 64, 83)" }}>
      <div className="container pt-2 pb-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>Website Devlopment Projets</b>
            </h3>
            <p className="text-light text-center">
              We provide dynamic, user-friendly website development services, delivering robust digital solutions tailored to client needs and we provide End-to-End API Integration. Our expertise spans platforms like Google Cloud, AWS, cPanel, and Hostinger, ensuring scalable and reliable results for businesses of all sizes.
              <br />
              Websites Using HTML, CSS, JS, JQuery, Bootstrap, PHP, Laravel,
              React, Angular
            </p>
            <h6 className="text-center">
              <Link to="/services/website-development" className="">
                <button className="btn btn-warning">Explore Services</button>
              </Link>
            </h6>
          </div>
            
          <div className="col-md-12 my-1">  
              <div className="row justify-content-center">
                <div className="col-md-4 my-1">
                  <div className="card card-body bg-transparent border-light h-100">               
                    <p className="lh-lg text-white">
                      <b>Informational Websites:</b>
                      <br />
                      BookMeriGadi.com <br />
                      WeBookWeb.com <br />
                      JansudharNashamuktikendra.com <br />
                      KushmandaHostel.in <br />
                      MojoClass.in <br />
                    </p> 
                  </div> 
                </div>
                <div className="col-md-4 my-1">             
                  <div className="card card-body bg-transparent border-light h-100">  
                    <p className="lh-lg text-white">
                      {/* <b>Complete Solutions with Admin & User Panels:</b><br /> */}

                      <b>BookMeriGadi.com:</b> A powerful CRM and booking system for efficient management and user engagement.<br />
                      <b>WeBookWeb.com:</b> A complete solution with admin and user panels, featuring an intuitive student and teacher panel system for paid internship and trainings. <br />
                    </p> 
                  </div>
                </div>
              </div>  
              <p className="lh-lg text-white">
              <b>Why Choose Us?</b> <br />
              We cater to a variety of requirements, from basic websites to advanced platforms with custom features. Our solutions are designed to be user-friendly, secure, and scalable.
              <br />
              Explore Our Demo Projects <br /> 
            </p>  
          </div>

          {webWorkData.map((item) => {
            return (
              <WebWorkCard
                key={item.id}
                webTitle={item.webTitle}
                webImage={item.webImage}
                webDescription={item.webDescription}
                webLink={item.webLink}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default WebWork;
