import WorkPageHome from "./work-subcomponents/WorkPageHome";
import WebWork from "./work-subcomponents/WebWork";
import GraphicWork from "./work-subcomponents/GraphicWork";
import AppWork from "./work-subcomponents/AppWork";
import { RefObject, useRef } from "react";
import {Helmet} from "react-helmet";

function Work() {
  const webWorkDivRef = useRef<HTMLDivElement>(null);
  const graphicWorkDivRef = useRef<HTMLDivElement>(null);
  const appWorkDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Helmet> 
          <meta charSet="utf-8" />
          <title>Vyomscode Projects | Custom Web & App Development Showcase</title>  
          <meta
            name="description"
            content="Vyomscode offers top-notch web and mobile app development services, crafting innovative digital solutions from scratch. Serving clients across India including Delhi, Mumbai, Varanasi, Lucknow, and more."
          /> 
          <meta 
          name="keywords" 
          content="website banane ki services, web development portfolio, app development showcase, projects by Vyomscode, website design kaise hota hai, app development kaise hota hai, best software developers, digital projects India, custom website banwaye, website kaise banwaye, website design kaha se karwaye, app kaise banwaye, top web design, award-winning app design, affordable software development, custom programming, digital transformation, Delhi web design, Mumbai app development, Varanasi web services, Lucknow development" />
      </Helmet>
      <WorkPageHome
        webWorkDivRef={webWorkDivRef}
        graphicWorkDivRef={graphicWorkDivRef}
        appWorkDivRef={appWorkDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="webWorkDiv" ref={webWorkDivRef}>
        <WebWork />
      </div>

      <div id="graphicWorkDiv" ref={graphicWorkDivRef}>
        <GraphicWork />
      </div>

      <div id="appWorkDiv" ref={appWorkDivRef}>
        <AppWork />
      </div>
    </div>
  );
}

export default Work;
