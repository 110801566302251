 
function AdAppProjects() {
  return (
    <section id="app-projects">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 ">
            <h3 className="text-center text-light">
              APP DEVLOPMENT PROJECTS
            </h3>
            <p className="text-center text-light mb-4">
              With expertise across multiple frontend and backend frameworks,
              we've successfully delivered diverse projects tailored to
              various industries. From React, Angular, Flask, and Node.js,
              we're equipped to bring your vision to life
            </p>  
          </div> 
          <div className="col-md-8 mb-3"> 
            <p className="text-light text-center lh-lg"> 
              We specialize in creating innovative, feature-rich applications tailored to diverse business needs. Our expertise includes seamless admin, sub-admin, and user panel integration, delivering perfect UI/UX, API support, SMS login, push notifications with custom sounds, and much more.
            </p>  
          </div> 
          <div className="row justify-content-center">
            <div className="col-12">
              <h4 className="text-light text-center">
                <b>Notable Projects</b>
              </h4>
            </div> 
            <div className="col-md-4">
              <div className="card card-body bg-transparent border-secondary h-100">
                <h6 className="text-light  text-center">
                  <b>BookMeriGadi App</b>
                </h6>
                <p className="lh-lg text-white  ">  
                  A comprehensive application designed for booking and service management. It features robust admin and user panels, intuitive UI, and advanced functionalities like SMS login and push notifications. Available on the Play Store for a hands-on experience.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-body bg-transparent border-secondary h-100">
                <h6 className="text-light  text-center">
                  <b>BMGConnect App</b>
                </h6>
                <p className="lh-lg text-white "> 
                  A versatile application for streamlined operations and enhanced connectivity. With admin, sub-admin, and user panel capabilities, it offers an exceptional user interface, custom notifications, and secure login options. Also available for tryouts on the Play Store.
                </p> 
              </div>
            </div>
            <div className="col-md-8"> 
              <h5 className="text-light mt-3">
                <b>Why Choose Us?</b>
              </h5>  
              <p className="lh-lg text-white mb-0"> 
                <b> Multi-Platform Expertise:</b> We develop apps for Android, iOS, and Windows with seamless functionality.
              </p>
              <p className="lh-lg text-white mb-0"> 
                <b>Advanced Features:</b> Includes admin panels, SMS login, API integration, and custom push notifications.
              </p>
              <p className="lh-lg text-white mb-0"> 
                <b>Scalable and Reliable:</b> Robust, secure, and tailored for growth.
              </p>
              <p className="lh-lg text-white mb-0"> 
                <b>User-Centric Design:</b> Intuitive UI/UX for an exceptional experience.
              </p>  
            </div>  
          </div>
        </div>
      </div>
    </section>
  )
}

export default AdAppProjects