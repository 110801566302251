import { RefObject } from "react";

function WorkPageHome({
  webWorkDivRef,
  graphicWorkDivRef,
  appWorkDivRef,
  scrollHandler,
}: {
  webWorkDivRef: RefObject<HTMLDivElement>;
  graphicWorkDivRef: RefObject<HTMLDivElement>;
  appWorkDivRef: RefObject<HTMLDivElement>;
  scrollHandler: (elem: RefObject<HTMLDivElement>) => void;
}) {
  return (
    <section id="work-home">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>Innovative Projects Showcase </b>
            </h3>
            <p className="text-light text-center">
              Exploring the Frontiers of Web Development, App Development, and
              Graphic Design
            </p>
            <div className="d-md-flex text-center justify-content-center">
              <span
                className="nav-link"
                onClick={() => {
                  scrollHandler(graphicWorkDivRef);
                }}
              >
                <button className="btn btn-outline-light  mx-1">Graphic Designs</button>
              </span>
              <br className="large-hide" />
              <div className="d-flex justify-content-center  my-md-0">
                <span
                  className="nav-link"
                  onClick={() => {
                    scrollHandler(webWorkDivRef);
                  }}
                >
                  <button className="btn btn-outline-light  mx-1">
                    Website Project
                  </button>
                </span>
                <span
                  className="nav-link"
                  onClick={() => {
                    scrollHandler(appWorkDivRef);
                  }}
                >
                  <button className="btn btn-outline-light  mx-1">
                    Application Project
                  </button>
                </span>  
              </div>
            </div>
          </div>

          {/* <div className="col-12"> */}
              {/* <p className="text-center text-light">
                Our web design services cover diverse categories, including many
                types of websites, and we are open to creating websites for other
                unique ideas. We believe in the power of customization and
                tailoring websites to suit your specific needs. Your satisfaction
                is our priority, and we're committed to delivering a visually
                appealing, user-friendly, and engaging website that aligns
                perfectly with your goals. Contact us to discuss your project and
                bring your vision to life.
              </p> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}

export default WorkPageHome;
