import { Route, Routes, useLocation } from "react-router-dom";
import MainPage from "./main-page/MainPage";
import PageNotFound from "./common-components/PageNotFound";
import ContactUs from "./other-pages/ContactUs";
import TermsAndConditions from "./other-pages/TermsAndConditions";
import PrivacyPolicy from "./other-pages/PrivacyPolicy";
import ServicesHome from "./Services/ServicesHome";
import Header from "./common-components/Header";
import ContactFooter from "./common-components/ContactFooter";
import Footer from "./common-components/Footer";
import Pricing from "./other-pages/Pricing";
// import ImageModal from "./main-page/imagePopup";
import ContactModal from "./main-page/contactform";

import Work from "./other-pages/Work";
import GoToTopButton from "./common-components/GoToTopButton";
import { useEffect, useState } from "react";
import PaidInternship from "./other-pages/PaidInternship";
import Careerpage from "./other-pages/Career";

function DefaultHome() {

  const { pathname } = useLocation(); 
  
    // const [showImageModal, setShowImageModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);

  const contactFormOpen = () => { 
    setShowContactModal(true);
  };

  useEffect(() => {  
    if (!sessionStorage.getItem("imageModalShown")) { 
      const timer = setTimeout(() => {
        // setShowImageModal(true);
        setShowContactModal(true);
        sessionStorage.setItem("imageModalShown", "true");
      }, 5000);  
      return () => clearTimeout(timer);
    }
  }, []);
  
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/services/*" element={<ServicesHome />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/paid-internship" element={<PaidInternship />} />
        <Route path="/career" element={<Careerpage />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <ContactFooter />
      <Footer />

      {/* <ImageModal show={showImageModal} onClose={() => setShowImageModal(false)} /> */}
      <ContactModal show={showContactModal} onClose={() => setShowContactModal(false)} />
      <GoToTopButton contactFormOpen={contactFormOpen}  />
    </div>
  );
}

export default DefaultHome;
